import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SingleImg from '../asstes/SingleImage.png'; // Ensure this path is correct
import { toast, ToastContainer } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css';
import LoggedIn from '../Components/LoggedIn';
import { FaTimes } from 'react-icons/fa'; // Importing close icon
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";



const CartPage = () => {
  const { login, register } = useKindeAuth();

  const { user } = useKindeAuth();
  localStorage.setItem('guestUserId', user?.id);



  console.log(user);
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState(''); // State for status message
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatingItemId, setUpdatingItemId] = useState(null);
  const [updatingItems, setUpdatingItems] = useState({}); // Object to track updating status for each cart item
  const token = localStorage.getItem('authToken');
  const [showModal, setShowModal] = useState(false);
  const fetchCartItems = async () => {

    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cart`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCartItems(response.data.cart.products);
        console.log(response.data.cart.products);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      } finally {
        setLoading(false);
      }
    } else {

      try {

        const cartItems = JSON.parse(localStorage.getItem('cartItems'));
        setCartItems(cartItems);
        console.log("local", cartItems);
      } catch (error) {
        console.error('Error parsing cart items:', error);
      }
      finally {
        setLoading(false);
      }

    }


  };



  useEffect(() => {
    fetchCartItems(); // Fetch cart items on mount
  }, []);

  // Check if the selected weight is a sample

  // Function to count sample products

  // Function to calculate the subtotal
  const calculateSubtotal = () => {
    if (token) {
      return cartItems.reduce((total, item) => {
        if (!item.product || !item.product.availableWeights) return total; // Check if product and availableWeights exist
  
        const selectedWeight = item.product.availableWeights.find((w) => w.weight === item.weight);
        const weightPrice = selectedWeight ? selectedWeight.price : 0;
  
        // Skip "Sample" weights, but add prices for others
        return isSample(item.weight) ? total : total + weightPrice * item.quantity;
      }, 0);
    } else {
      if (cartItems) {
        return cartItems.reduce((total, item) => {
          // Skip "Sample" weights, but add prices for others
          return isSample(item.weight) ? total : total + (item.price * item.quantity);
        }, 0);
      } else {
        return 0;
      }
    }
  };
  

  //write a function to check cartitems.availableweights is sample by checking weight.weight=="Sample"

  // console.log(cartItems[0].product.availableWeights[0].weight);
  const isSample = (weight) => {
    // Check if any weight is "Sample"
    if (weight == "Sample") {
      return true;
    }
    else {
      return false;
    }
  };



  const subtotal = calculateSubtotal();
  const shipping = cartItems?.length === 0 || cartItems == null ? 0 : 30;// Free shipping
  // const shipping = cartItems?.length === 0 || cartItems == null ? 0 : 0;// Free shipping

  const total = subtotal + shipping; // Final total calculation



  const handleUpdateQuantity = async (cartItemId, weight, currentQuantity, product) => {
    // localStorage.removeItem('cartItems');


    const newQuantity = currentQuantity + 1; // Increment the quantity by 1

    // Prevent invalid or negative quantities
    if (newQuantity < 0) return;

    const token = localStorage.getItem('authToken');
    if (token) {
      // Find the corresponding available weight's price
      const selectedWeight = product.availableWeights.find((w) => w.weight === weight);
      const weightPrice = selectedWeight ? selectedWeight.price : 0; // Allow price to be 0 for sample weights
      setUpdatingItems((prev) => ({ ...prev, [cartItemId]: true }));
      setStatusMessage('Updating...');
      console.log('Updating cart item:', cartItemId, 'with new quantity:', newQuantity, 'and weight:', weight);

      try {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cart/${cartItemId}`, {
          quantity: newQuantity,
          weight: weight, // Include the weight
          price: weightPrice, // Allow this even if weightPrice is 0
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // Refresh cart items after update
        fetchCartItems();
      } catch (error) {
        console.error('Error updating cart quantity:', error.response?.data || error);
      } finally {
        setUpdatingItems((prev) => ({ ...prev, [cartItemId]: false })); // Reset updating state
        setStatusMessage(''); // Clear status message
      }
    } else {

      if (newQuantity < 0) return;
      const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      // Find the index of the item to update
      const itemIndex = cartItems.findIndex((item) => item.product === cartItemId && item.weight === weight);
      if (itemIndex !== -1) {
        // Update the quantity for the matched item
        cartItems[itemIndex].quantity = newQuantity;
        localStorage.setItem('cartItems', JSON.stringify(cartItems));

      } else {
        console.warn('Cart item not found in local storage');

      }
      fetchCartItems();
    }

  };




  const handleRemoveProduct = async (cartItemId, weight, currentQuantity, product) => {
    const token = localStorage.getItem('authToken');



    if (token) {
      if (!product || !product.availableWeights) {
        console.error('Product is not defined or does not have availableWeights');
        return; // Exit the function if product is not defined
      }

      const selectedWeight = product.availableWeights.find((w) => w.weight === weight);
      const weightPrice = selectedWeight ? selectedWeight.price : 0;

      setUpdatingItems((prev) => ({ ...prev, [cartItemId]: true }));
      setStatusMessage('Removing...');

      try {
        if (currentQuantity > 1) {
          // Send PUT request to reduce the quantity of the product
          await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cart/${cartItemId}`,
            {
              weight,
              quantity: currentQuantity - 1,  // Decrease quantity by 1
              product,
              price: weightPrice,

            },
            {
              headers: { Authorization: `Bearer ${token}` },
            });
        } else {
          // If the quantity is 1, remove the product completely
          await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cart/${cartItemId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        }

        // Refresh the cart items after the operation
        fetchCartItems();
      } catch (error) {
        console.error('Error modifying product in cart:', error.response?.data || error);
      }
      finally {
        setUpdatingItems((prev) => ({ ...prev, [cartItemId]: false }));
        setStatusMessage(''); // Clear status message
      }
    } else {

      const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      console.log(cartItems);

      // Find the index of the item to update
      const itemIndex = cartItems.findIndex((item) => item.product === cartItemId && item.weight === weight);
      if (itemIndex !== -1) {
        // Update the quantity for the matched item
        if (currentQuantity > 1) {
          cartItems[itemIndex].quantity = currentQuantity - 1;
          localStorage.setItem('cartItems', JSON.stringify(cartItems));

        } else {
          // If the quantity is 1, remove the product completely
          cartItems.splice(itemIndex, 1);
          localStorage.setItem('cartItems', JSON.stringify(cartItems));
        }

      } else {
        console.warn('Cart item not found in local storage');
      }
      fetchCartItems();

    }
  };


  const countSampleProducts = () => {
    if (token) {

      const sampleProducts = cartItems.filter(item =>
        item.product?.availableWeights?.some(
          weight => weight.weight === "Sample" && weight.weight === item.weight
        )
      );

      // Use a Set to count unique product IDs (to ensure no duplicate samples)
      const uniqueSampleProducts = new Set(sampleProducts.map(item => item.product._id));

      return uniqueSampleProducts.size;

    } else {

      const sampleProducts = cartItems?.filter(item =>
        item.weight === "Sample"
      );

   
        const uniqueSampleProducts = new Set(sampleProducts.map(item => item._id));
        return uniqueSampleProducts.size;
    
    }


  };


  const countTotalProducts = () => {
    if (cartItems?.length) {
      return cartItems.length;
    }
    else {
      return 0
    }
  };


  const handleProceedToCheckout = () => {


    const sampleCount = countSampleProducts();
    const productCount = countTotalProducts();
    if (productCount === 0) {
      toast.warn('Your cart is empty. Please add products to proceed to checkout.');
      return;
    }

    if (token) {
      console.log("inside token", token);
      if (sampleCount === 0 || sampleCount === 3) {
        const subtotal = calculateSubtotal();
        const shipping = 30;
        // const shipping = 0;

        const total = subtotal + shipping;
        navigate('/billing', {
          state: { totalAmount: total, subtotal, shipping },
        });
      } else {
        toast.info('You need exactly three sample products to proceed to checkout.');
      }
    } else {
      //if user is not undefined
      if (user) {
        console.log("inside user", user, sampleCount);
        if (sampleCount === 0 || sampleCount === 3) {
          const subtotal = calculateSubtotal();
          const shipping = 30;
          // const shipping = 0;

          const total = subtotal + shipping;
          navigate('/billing', {
            state: { totalAmount: total, subtotal, shipping, user: user },
          });
        } else {
          toast.info('You need exactly three sample products to proceed to checkout.');
        }
      } else {
        setShowModal(true);
      }

    }
  };

  const handleTitleClick = (productId) => {
    navigate(`/product?productId=${encodeURIComponent(productId)}`);

    window.scrollTo({
      top: 0,
      behavior: 'smooth'  // Optional: adds a smooth scroll effect
    });
  };

  return (
    <div className="bg-[#181818] from-blue-900 to-black text-white px-[20px] pb-8 md:px-[80px] pt-[60px]">
      {/* Progress Bar */}
      <ToastContainer
        position="top-right"  // Ensures it stays in the top-right corner
        autoClose={2000}      // Adjust the auto-close time if needed
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#ECBE56',
          color: 'black',
          display: 'flex',
          width: 'auto',    // Allows full message display
          padding: '10px',
        }}
        progressStyle={{
          backgroundColor: 'black',
        }}
        style={{
          position: 'fixed',  // Fixed positioning to keep it outside the card
          top: '100px',        // Distance from the top of the page
          right: '40px',      // Distance from the right of the page
          zIndex: 9999,       // Ensures it appears above other elements
        }}
      />
      <div className="flex flex-col items-center justify-center mb-16">

        <div className="flex items-center md:w-[742px] w-full">
          {/* Step 1 */}
          <div className="flex flex-col items-center">
            <div className="w-[44px] h-[44px] md:w-[84px] md:h-[84px] bg-[#ECBC56] rounded-full flex items-center justify-center  md:text-[50px] text-[30px]  font-semibold text-black">1</div>
            <span className="mt-2 text-[13px] md:text-[25px] text-[#ECBC56] font-light">Order Details</span>
          </div>

          {/* Dashed Line */}
          <div className="flex-grow h-0 mb-6 md:mb-12 border-t-2 border-[#ECBC56] border mx-4"></div>

          {/* Step 2 */}
          <div className="flex flex-col items-center">
            <div className="w-[44px] h-[44px] md:w-[84px] md:h-[84px] bg-transparent border border-[#ECBC56] rounded-full  md:text-[50px] text-[30px]  font-semibold text-[#ECBC56] flex items-center justify-center">2</div>
            <span className="mt-2 text-[13px] md:text-[25px] text-white font-light">Delivery & Payment</span>
          </div>

          {/* Dashed Line */}
          <div className="flex-grow h-0 mb-6 md:mb-12 border-t-2 border-[#ECBC56] border-dashed mx-4"></div>

          {/* Step 3 */}
          <div className="flex flex-col items-center">
            <div className="w-[44px] h-[44px] md:w-[84px] md:h-[84px] bg-transparent border border-[#ECBC56] rounded-full  md:text-[50px] text-[30px] text-[#ECBC56] font-semibold flex items-center justify-center">3</div>
            <span className="mt-2 text-[13px] md:text-[25px] text-white font-light">Confirmation</span>
          </div>
        </div>
      </div>
      <div>
        <h2 className="md:text-[50px] text-[40px] font-small mb-8 text-center md:text-left">My Cart</h2>
        <div className="flex flex-col md:justify-between md:flex-row lg:space-x-4">

          <div className="flex-1">
            {loading ? (
              <p>Loading cart...</p>
            ) : cartItems?.length === 0 || cartItems === null ? (
              <p className='text-white text-center md:text-left py-4 text-[20px]'>No items in the cart</p>
            ) : (
              cartItems?.map((item) => {
                const weight = item.weight || 'N/A';  // Ensure weight is displayed correctly
                const price = item.price || 0;        // Ensure price is displayed correctly
                const productImage = item.product?.images?.[0]?.url || item.image;
                const productId = item.product?._id;

                return (

                  <div
                    key={`${productId}-${weight}`}  // Unique key based on productId and weight
                    className="px-2 py-2 md:px-8 md:py-8 md:w-[700px] md:h-[231px] bg-[#222222] rounded-[20px] mb-6 w-full h-auto flex flex-col md:flex-row items-start md:items-center "
                   

                  >
                    {/* <FaTimes 
                              className="md:hidden block ml-auto text-white cursor-pointer "  // Add close icon style
                              onClick={() => handleRemoveProduct(item._id, item.weight, 1, item.product)} // Remove the item completely
                              size={24}  // Icon size
                            />
                            
                        <img
                          src={productImage}
                          alt={item.product?.name || 'Product Image'}
                          className="mb-[10px] md:mb-[0px] md:w-[157px] md:h-[191px] w-full h-full object-contain md:mr-[38px]"
                        /> */}
                    <div className="relative w-full md:w-[250px]"> {/* Added a relative container */}
                      <FaTimes
                        className="md:hidden absolute top-2 right-2 text-white cursor-pointer"  // Positioned in the top right corner
                        onClick={() => handleRemoveProduct(item._id, item.weight, 1, item.product)} // Remove the item completely
                        size={24}  // Icon size
                      />
                      <div className='h-[190px] w-full md:w-[250px]'>

                        <img
                          src={productImage}
                          alt={item.product?.name || 'Product Image'}
                          className="rounded-lg w-full h-full object-cover"
                        />
                      </div>
                    </div>
                    <div className="mt-2 md:mt-0 pl-2 md:pl-8 flex flex-col w-full hover:cursor-pointer"   onClick={() => handleTitleClick(item.product?._id || item._id)}>
                      <h6 className="md:text-[30px] text-[26px] font-semibold">{item.product?.name || item.name}</h6>
                      <p className="mt-2 text-lg sm:text-2xl text-[#ECBC56] text-[24px] font-medium">₹{price}</p>
                      <p className="mt-2 text-lg">Weight: {weight}</p>
                      {isUpdating && (
                        <p className="text-yellow-500">{statusMessage}</p>
                      )}

                      {/* Action Buttons */}
                      <div className="w-full flex flex-row gap-2 mt-4 pb-4">
                       
                        {!updatingItems[item._id] && (
                          !isSample(item.weight) ? (
                            <>
                            <h2 >Select Quantity</h2>
                            <div className="flex items-center">
                              <button
                                onClick={() => handleRemoveProduct(item._id, item.weight, item.quantity, item.product)}
                                className="py-2 px-[19px] md:px-[17px] bg-[#181818] text-white"
                              >
                                -
                              </button>

                              <span className="py-2 px-[19px] bg-[#181818] text-white">
                                {item.quantity}
                              </span>

                              <button
                                onClick={() => handleUpdateQuantity(item._id, item.weight, item.quantity, item.product)}
                                className="py-2 px-5 md:px-[17px] bg-[#181818] text-white"
                              >
                                +
                              </button>
                            </div>
                            </>
                          ) : null
                        )}


                        {updatingItems[item._id] && (
                          <p className="text-yellow-500 bg-[#00071A] px-6 py-2">{statusMessage}</p>  // Display updating message only for the specific item
                        )}

                       
                      </div>
                    </div>
                    <FaTimes
                          className="hidden md:block ml-auto text-white cursor-pointer mt-[-160px]"  // Add close icon style
                          onClick={() => handleRemoveProduct(item._id, item.weight, 1, item.product)} // Remove the item completely
                          size={24}  // Icon size
                        />
                  </div>
                );
              }))}
          </div>

          {/* Order Summary */}
          <div className="bg-[#222222] border border-[#ECBC56] md:p-9 p-5 rounded-[20px] md:w-[451px] md:h-[450px] w-full h-auto flex flex-col">
            <div>
              <h3 className="text-[23px] md:text-[30px] text-[#ECBC56] font-semibold mb-4">ORDER TOTAL</h3>
              <div className="h-[171px]">
                <div className="flex justify-between">
                  <span className="text-[#DBDBDB] text-[18px] md:text-[25px] font-light">Subtotal</span>
                  <span className="text-[#DBDBDB] text-[18px] md:text-[25px] font-light">₹{subtotal}</span>
                </div>
                <div className="flex justify-between mb-[30px]">
                  <span className="text-[#DBDBDB] text-[18px] md:text-[25px] font-light">Shipping</span>
                  <span className="text-[#DBDBDB] text-[18px] md:text-[25px] font-light">₹{shipping}</span>
                </div>
                <hr className="my-4 border-[#ECBC56] border-dashed mb-[30px]" />
                <div className="flex justify-between">
                  <span className="text-[#ECBC56] text-[18px] md:text-[25px] font-light">Total</span>
                  <span className="text-[#ECBC56] text-[18px] md:text-[25px] font-light">₹{total}</span>
                </div>
              </div>
            </div>
            <div className="mt-[10px] md:mt-[40px]">
              <button
                onClick={handleProceedToCheckout}
                className="md:w-[384px] md:h-[52px] w-full h-auto text-[18px] md:text-[25px] mb-[15px] bg-[#ECBC56] text-black font-normal rounded-[5px] py-[4px] md:py-[0px] text-center"
              >
                Checkout
              </button>
              <button
                onClick={() => navigate('/store')}
                className="md:w-[384px] md:h-[52px] w-full h-auto text-[18px] md:text-[25px] bg-transparent border border-[#ECBC56] text-white font-light rounded-[5px] py-[4px] md:py-[0px] text-center"
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80  z-50">
            <div className="mx-4 bg-[#222222] rounded-lg p-4 md:w-2/3 w-full border border-[#ECBC56]">
              <button className="float-right text-white" onClick={() => setShowModal(false)}>
                <FaTimes size={20} />
              </button>
              <div className=" md:p-12 flex flex-col items-center gap-4 ">
                <h1 className="text-white text-[23px] md:text-[30px]  font-semibold ">Welcome</h1>
                <h3 className="text-[#DBDBDB] text-[18px] md:text-[25px] font-light">Sign in or create an account as Guest</h3>
                <div className="flex gap-4">
                  <button className="bg-[#ECBC56] text-black px-6 py-2 rounded-md hover:scale-105 transition" onClick={login}>
                    Sign in
                  </button>
                  <button className="bg-[#ECBC56] text-black px-6 py-2 rounded-md hover:scale-105 transition" onClick={register}>
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartPage;