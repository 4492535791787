
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import eclipse from '../asstes/ellipse.jpeg';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from './Spinner';
import innerImage from '../asstes/Promotional Section.png';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedStars, setSelectedStars] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [reviewerName, setReviewerName] = useState('');
  const [product, setProduct] = useState(null);
  
  

  // Extract productId from query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get('productId');
  console.log(productId);

  // Fetch reviews based on the productId
  useEffect(() => {
    const fetchReviews = async () => {
      if (!productId) return;
      setLoading(true);
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/review/${productId}`);
        setReviews(data.reviews);
        setLoading(false);
      } catch (error) {
        setError('Error fetching reviews. Please try again later.');
        setLoading(false);
      }
    };
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/product/${productId}`);
        if (response.data) {
          const fetchedProduct = response.data.product;
          setProduct(fetchedProduct);


        } else {
          setError('No product found');
        }
        setLoading(false);
      } catch (err) {

        setLoading(false);
      }
    };


    fetchProduct();
    fetchReviews();
  }, [productId]);

  if (loading) return <p><Spinner/></p>;
  if (error) return <p>{error}</p>;

  const handleStarClick = (index) => {
    setSelectedStars(index + 1);
  };

 

  const handleSubmit = async () => {
    if (!reviewerName || !selectedStars || !reviewText) {
      setError('Please fill in all fields');
      return;
    }
    const token = localStorage.getItem('authToken');
    if (!token) {
      toast.error('You need to be logged in to add review.');
      return null;
    }

    setLoading(true);
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/review`,
        {
          name: reviewerName,
          rating: selectedStars,
          comment: reviewText,
          productId,
        },
        {
          headers: {
            Authorization:` Bearer ${token}`,
          },
        }
      );

      const newReview = {
        _id: data._id,
        name: reviewerName,
        rating: selectedStars,
        comment: reviewText,
      };

      setReviews((prevReviews) => [...prevReviews, newReview]);
      setSuccess('Review submitted successfully!');

      toast.success('Review submitted successfully!');
      setModalOpen(false);
      setSelectedStars(0);
      setReviewText('');
      setReviewerName('');
    } catch (error) {
      toast.error('Error submitting the review. Please try again later.');
    }
    setLoading(false);
  };

  // Render star rating as percentage
  const renderStarRating = (rating) => {
    const percentage = (rating / 5) * 100;
    return percentage.toFixed(2) + '%';
  };

  const rating = product?.ratings;



  return (
    <div className="bg-[#181818]w-full md:px-[80px] px-[20px]  text-white p-10 mx-auto gap-[80px]">
      <ToastContainer
        position="top-right"  // Ensures it stays in the top-right corner
        autoClose={2000}      // Adjust the auto-close time if needed
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#ECBE56',
          color: 'black',
          display: 'flex',
          width: 'auto',    // Allows full message display
          padding: '10px',
        }}
        progressStyle={{
          backgroundColor: 'black',
        }}
        style={{
          position: 'fixed',  // Fixed positioning to keep it outside the card
          top: '100px',        // Distance from the top of the page
          right: '40px',      // Distance from the right of the page
          zIndex: 9999,       // Ensures it appears above other elements
        }}
      />
      <h2 className="md:text-[44px] text-[28px] font-semibold text-center mb-[20px]">
        See What Others Are Saying
      </h2>
      <div className="mx-auto border-dashed border-t-2 border-[#ECBC56] w-[230px] md:w-[670px] mb-[20px]"></div>
      <p className="text-center mb-[30px] text-gray-300 md:text-[32px] text-[16px] font-normal">
        Authentic stories of satisfaction and experience, fostering enduring trust with each interaction.
      </p>

      {/* Two-column layout */}
      <div className="flex flex-col md:flex-row md:space-x-8 w-full mb-[10px]">
  {/* Review Summary and Add Review Button */}
  <div className="rounded-[20px] h-auto flex flex-col bg-[#222222] py-4 md:py-[20px] px-4 md:px-[20px] mb-6 md:mb-0 items-center ">
    <div className="flex md:flex-row flex-row items-center justify-start gap-[5px] ">
      <div className="rounded-[7px] w-[70px] h-[33px] flex items-center bg-[#ECBC56] px-4 py-1 text-black text-lg font-bold mb-1">
        <div className="flex flex-row gap-[2px] items-center ">
          <div>{rating} ★</div>
         
        </div>
      </div>
      <div className="text-[22px] font-normal">out of 5</div>
    </div>
    

    {/* Add review button */}
    <button
      onClick={() => setModalOpen(true)}
      className="w-[66%] mt-4 bg-[#ECBC56] text-black rounded-lg px-4 py-2"
    >
      Add Review
    </button>

    {/* Star percentage logic */}
    <div className="flex flex-col gap-[10px] text-[24px] m-3">
      {[100, 80, 60, 40, 20].map((percent, i) => (
        <div key={i} className="flex items-center space-x-2 text-center">
          <div className="flex space-x-1">
            {[...Array(5)].map((_, starIndex) => {
              const starPercentage = (percent / 100) * 5;
              const fillLevel = Math.min(starPercentage - starIndex, 1) * 100;

              return (
                <div
                  key={starIndex}
                  className="relative w-6 h-6"
                  style={{
                    background: `linear-gradient(90deg, #ECBC56 ${fillLevel}%, white ${fillLevel}%)`,
                    WebkitMask: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 .587l3.668 7.568 8.302 1.208-6.006 5.848 1.42 8.283L12 18.897l-7.384 3.88 1.42-8.283-6.006-5.848 8.302-1.208z'/%3E%3C/svg%3E\")",
                    WebkitMaskRepeat: 'no-repeat',
                    WebkitMaskSize: 'contain',
                    mask: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M12 .587l3.668 7.568 8.302 1.208-6.006 5.848 1.42 8.283L12 18.897l-7.384 3.88 1.42-8.283-6.006-5.848 8.302-1.208z'/%3E%3C/svg%3E\")",
                    maskRepeat: 'no-repeat',
                    maskSize: 'contain',
                  }}
                ></div>
              );
            })}
          </div>
          <p className="text-[16px]">{percent}%</p>
        </div>
      ))}
    </div>
  </div>

  {/* Reviews Container */}
  <div className="flex-1 bg-[#222222] p-4 md:p-6 rounded-[20px] overflow-y-auto max-h-[500px]">
    {Array.isArray(reviews) && reviews.length ? (
      reviews.map((review) => (
        <div key={review._id} className="mb-6 flex flex-col">
          <div className='flex flex-col'>
            <div className='flex flex-row gap-4'>
              <h3 className="text-xl font-bold">{review.name}</h3>
              <div className="flex">
                {[...Array(review.rating)].map((_, i) => (
                  <span key={i} className="text-[#ECBC56] text-[20px]">★</span>
                ))}
              </div>
            </div>
            <div className='flex'>
              <p className="text-gray-400">{review.comment}</p>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p className="text-gray-400">No reviews yet for this product.</p>
    )}
  </div>
</div>


      {/* Modal for Adding Review */}
      {isModalOpen && (
  <div className="fixed inset-0 mt-28 flex items-center justify-center p-5 z-10 bg-black bg-opacity-85">
    <div className="bg-[#222222] p-8 rounded-[30px] w-[1050px] h-[500px] shadow-lg">
      <div className="mb-4">
        <p className='text-xl font-semibold text-center text-[#ECBC56]' >ADD YOUR FEEDBACK</p>
        <h2 className="text-[22px] text-[#ECBC56] font-normal mb-2">Name:</h2>
        <input
          type="text"
          className="bg-[#181818] w-full text-xl text-white border border-gray-300 p-3 rounded focus:outline-none focus:border-[#ECBC56] transition"
          placeholder="Enter your name..."
          value={reviewerName}
          onChange={(e) => setReviewerName(e.target.value)}
        />
      </div>

      <div className="flex flex-col gap-[10px] items-start mb-4">
        <h2 className="text-[22px] text-[#ECBC56] font-normal">Review :</h2>
        <div className="flex mb-2">
          {[...Array(5)].map((_, index) => (
            <span
              key={index}
              className={`cursor-pointer text-[30px] ${index < selectedStars ? 'text-[#ECBC56]' : 'text-gray-300'}`}
              onClick={() => handleStarClick(index)}
            >
              ★
            </span>
          ))}
        </div>
      </div>

      <h2 className="text-[22px] text-[#ECBC56] font-normal mb-2">Comment :</h2>
      <textarea
        className="bg-[#181818] w-full text-xl text-white border border-gray-300 p-3 rounded focus:outline-none focus:border-[#ECBC56] transition"
        placeholder="Write your review..."
        value={reviewText}
        onChange={(e) => setReviewText(e.target.value)}
      />

      <div className="flex justify-end mt-6">
        <button
          onClick={handleSubmit}
          className="bg-[#ECBC56] text-black rounded-lg px-6 py-2 transition hover:bg-[#d9a24d]"
          disabled={loading}
        >
          Submit
        </button>
        <button
          onClick={() => setModalOpen(false)}
          className="ml-4 text-gray-300 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-600 transition"
        >
          Cancel
        </button>
      </div>

      {error && <p className="text-red-500 mt-2">{error}</p>}
      {success && <p className="text-green-500 mt-2">{success}</p>}
    </div>
  </div>
)}


    </div>
  );
};

export default Reviews;
