import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import razorpayLogo from '../asstes/razorpay.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from './Spinner';

const CheckoutPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const { totalAmount, shipping, subtotal, user } = location.state || { totalAmount: 0, shipping: 0, subtotal: 0, user: null }; // Destructure subtotal
    const userId = localStorage.getItem('userId');

    const [cartItems, setCartItems] = useState([]);
    const [notification, setNotification] = useState({ message: '' });
    const [errors, setErrors] = useState({});
    const [billingDetails, setBillingDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        shippingInfo: {
            address: '',
            city: '',
            state: '',
            country: 'India',
            zip: '',
        },
        user: userId,
        guestUser: user?.id,
        totalAmount: totalAmount,
    });
    const token = localStorage.getItem('authToken');
    const fetchCartItems = async () => {

        if (user && token) {
            toast.error('Please log in to checkout');
            navigate('/login');
            return;
        }
        if (token) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cart`, {
                    headers: { Authorization: `Bearer ${token} ` },
                });
                setCartItems(response.data.cart.products);
            } catch (error) {
                console.error('Error fetching cart items:', error);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                const cartItems = JSON.parse(localStorage.getItem('cartItems'));
                setCartItems(cartItems);
            } catch (error) {
                console.error('Error parsing cart items:', error);
            } finally {
                setLoading(false);
            }
        }


    };

    useEffect(() => {
        fetchCartItems();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (['address', 'city', 'state', 'zip'].includes(name)) {
            setBillingDetails({
                ...billingDetails,
                shippingInfo: { ...billingDetails.shippingInfo, [name]: value },
            });
        } else {
            setBillingDetails({ ...billingDetails, [name]: value });
        }
    };
    const validateInputs = () => {
        let validationErrors = {};

        // First name validation
        if (!billingDetails.firstName.trim()) {
            validationErrors.firstName = 'First name is required';
        }

        // Last name validation
        if (!billingDetails.lastName.trim()) {
            validationErrors.lastName = 'Last name is required';
        }

        // Email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!billingDetails.email.trim()) {
            validationErrors.email = 'Email is required';
        } else if (!emailPattern.test(billingDetails.email)) {
            validationErrors.email = 'Please enter a valid email';
        }

        // Phone number validation (should be 10 digits)
        const phonePattern = /^[0-9]{10}$/;
        if (!billingDetails.phone.trim()) {
            validationErrors.phone = 'Phone number is required';
        } else if (!phonePattern.test(billingDetails.phone)) {
            validationErrors.phone = 'Phonenumber must be 10 digits';
        }

        // Address validation
        if (!billingDetails.shippingInfo.address.trim()) {
            validationErrors.address = 'Address is required';
        }

        // City validation
        if (!billingDetails.shippingInfo.city.trim()) {
            validationErrors.city = 'City is required';
        }

        // State validation
        if (!billingDetails.shippingInfo.state.trim()) {
            validationErrors.state = 'State is required';
        }

  

        // Zip code validation
        if (!billingDetails.shippingInfo.zip.trim()) {
            validationErrors.zip = 'Pin code is required';
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };
    const loadRazorpayScript = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                reject(new Error('Failed to load Razorpay script'));
            };
            document.body.appendChild(script);
        });
    };

    let orderData = {};

    const handlePayment = async () => {
        const token = localStorage.getItem('authToken');
        const userId = localStorage.getItem('userId');

        if (user && token) {
            toast.error('Please log in to place an order');
            return;
        }



        if (token) {
            orderData = {
                amount: totalAmount,
                user: userId,
                shippingInfo: billingDetails.shippingInfo,
                products: cartItems.map(item => ({
                    productId: item.product._id,
                    quantity: item.quantity,
                    name: item.product.name,
                })),
                totalAmount: totalAmount,
            };

        } else {
            orderData = {
                amount: totalAmount,
                user: userId,
                guestUser: user,
                shippingInfo: billingDetails.shippingInfo,
                products: cartItems.map(item => ({
                    productId: item.product,
                    quantity: item.quantity,
                    name: item.name,
                    image: item.image,
                })),
                totalAmount: totalAmount,
            };
        }



        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/process`, orderData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const { orderId } = response.data;

            await loadRazorpayScript();
            let paymentData = {};

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                amount: totalAmount * 100,
                currency: "INR",
                name: "Paarampayia",
                description: "Test Transaction",
                order_id: orderId,
                handler: async (response) => {
                    if (token) {
                        paymentData = {
                            orderId: orderId,
                            paymentId: response.razorpay_payment_id,
                            signature: response.razorpay_signature,
                            orderData: {
                                user: userId,
                                billingDetails: billingDetails,
                                shippingInfo: billingDetails.shippingInfo,
                                products: cartItems.map(item => ({
                                    productId: item.product._id,
                                    quantity: item.quantity,
                                    name: item.product.name,
                                    weight: item.weight

                                })),
                                totalAmount: totalAmount

                            },
                        };
                    } else {
                        paymentData = {
                            orderId: orderId,
                            paymentId: response.razorpay_payment_id,
                            signature: response.razorpay_signature,
                            orderData: {
                                user: userId,
                                billingDetails: billingDetails,
                                shippingInfo: billingDetails.shippingInfo,
                                products: cartItems.map(item => ({
                                    productId: item.product,
                                    quantity: item.quantity,
                                    name: item.name,
                                    image: item.image,
                                    weight: item.weight
                                })),
                                totalAmount: totalAmount
                            },
                            guestUser: user,
                        };
                    }


                    try {

                        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/callback`, paymentData, {
                            headers: { Authorization: `Bearer ${token}` },
                        });



                        alert('Payment successful!');
                        
                       
                        // Navigate to OrderConfirmation page
                        navigate('/confirmation', {
                            state: {
                                orderId: orderId,
                                billingDetails: billingDetails,
                                cartItems: cartItems,
                                totalAmount: totalAmount,
                            },
                        });
                    } catch (error) {
                        console.error('Error verifying payment:', error);
                        toast.error('Payment verification failed. Please try again.');
                    }

                },
                prefill: {
                    name: `${billingDetails.firstName} ${billingDetails.lastName}`,
                    email: billingDetails.email,
                    contact: billingDetails.phone,
                },
                theme: {
                    color: "#F37254",
                },
                image: 'https://firebasestorage.googleapis.com/v0/b/paarampariya-1.appspot.com/o/logo.png?alt=media&token=1426dcf4-9253-43c5-9b26-1fe73ddd19d0'
            };

            const razorpay = new window.Razorpay(options);
            razorpay.open();
            setLoading(false);
        } catch (error) {
            console.error('Error initiating payment:', error);
            toast.error('Payment initiation failed. Please try again.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission

        // Validate inputs first
        if (validateInputs()) {
            // If validation is successful, proceed to handle payment
            handlePayment();
        } else {
            // If validation fails, you can optionally show a toast or some other notification
            toast.error('Please fill in all fields before proceeding.');
        }
    };


    return (


        <div className="bg-[#181818] from-blue-900 to-black text-white px-[20px] md:px-[80px] pt-[50px]">

            {loading ? (<Spinner />) : (
                <>
                    {/* Progress Bar */}
                    <div className="flex flex-col items-center justify-center mb-16">

                        <div className="flex items-center md:w-[742px] w-full">
                            {/* Step 1 */}
                            <div className="flex flex-col items-center">
                                <div className="w-[44px] h-[44px] md:w-[84px] md:h-[84px] bg-[#ECBC56] rounded-full flex items-center justify-center  md:text-[50px] text-[30px]  font-semibold text-black">1</div>
                                <span className="mt-2 text-[13px] md:text-[25px] text-white font-light">Order Details</span>
                            </div>

                            {/* Dashed Line */}
                            <div className="flex-grow mb-6 md:mb-12 h-0 border-t-2 border-[#ECBC56] border mx-4"></div>

                            {/* Step 2 */}
                            <div className="flex flex-col items-center">
                                <div className="w-[44px] h-[44px] md:w-[84px] md:h-[84px] bg-[#ECBC56] border border-[#ECBC56] rounded-full  md:text-[50px] text-[30px]  font-semibold text-black flex items-center justify-center">2</div>
                                <span className="mt-2 text-[13px] md:text-[25px] text-[#ECBC56] font-light">Delivery & Payment</span>
                            </div>

                            {/* Dashed Line */}
                            <div className="flex-grow h-0  mb-6 md:mb-12 border-t-2 border-[#ECBC56] border-dashed mx-4"></div>

                            {/* Step 3 */}
                            <div className="flex flex-col items-center">
                                <div className="w-[44px] h-[44px] md:w-[84px] md:h-[84px] bg-transparent border border-[#ECBC56] rounded-full  md:text-[50px] text-[30px] text-[#ECBC56] font-semibold flex items-center justify-center">3</div>
                                <span className="mt-2 text-[13px] md:text-[25px] text-white font-light">Confirmation</span>
                            </div>
                        </div>
                    </div>
                    {/* {notification.message && (
<div className="fixed top-4 right-4 bg-[#ECBC56] text-black px-4 py-2 rounded shadow-lg z-50 text-center">
    {notification.message}
</div>
)} */}
                    <ToastContainer
                        position="top-right"  // Ensures it stays in the top-right corner
                        autoClose={2000}      // Adjust the auto-close time if needed
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        toastStyle={{
                            backgroundColor: '#ECBE56',
                            color: 'black',
                            display: 'flex',
                            width: 'auto',    // Allows full message display
                            padding: '10px',
                        }}
                        progressStyle={{
                            backgroundColor: 'black',
                        }}
                        style={{
                            position: 'fixed',  // Fixed positioning to keep it outside the card
                            top: '100px',        // Distance from the top of the page
                            right: '40px',      // Distance from the right of the page
                            zIndex: 9999,       // Ensures it appears above other elements
                        }}
                    />
                    <div className='pb-20 flex flex-col gap-[34px] md:flex-row justify-between '>
                        <form className="flex flex-col md:flex-row justify-between gap-8">
                            {/* Left Section - Billing and Delivery Details */}

                            <div className="">
                                {/* Billing Details */}
                                <div className="mb-6 md:w-[629px] md:h-[287px] w-full h-auto">
                                    <h2 className=" text-[20px] md:text-[32px]   font-light mb-4">Billing Details</h2>
                                    <div className="flex flex-col md:flex-row gap-4">

                                        <div>
                                            <input type="text" name='firstName' placeholder="First Name" className="flex-1 w-full md:w-[307px] p-2 rounded-md bg-[#222222] text-[16px] md:text-[20px] font-light  text-white" onChange={handleInputChange} required />
                                            {errors.firstName && <p className="text-red-500">{errors.firstName}</p>}
                                        </div>
                                        <div>
                                            <input type="text" name='lastName' placeholder="Last Name" className="flex-1 p-2 w-full  md:w-[307px] rounded-md bg-[#222222] text-[16px] md:text-[20px] font-light  text-white" onChange={handleInputChange} required />
                                            {errors.lastName && <p className="text-red-500">{errors.lastName}</p>}
                                        </div>
                                    </div>
                                    <div>
                                        <input type="email" name='email' placeholder="Email Address (same as login)" className="p-2 rounded-md w-full bg-[#222222]  text-[16px] md:text-[20px] font-light text-white mt-4" onChange={handleInputChange} required />
                                        {errors.email && <p className="text-red-500">{errors.email}</p>}
                                    </div>

                                    {/* <div className="flex items-center gap-[10px]  bg-[#222222] w-1/2  md:w-full  p-2 rounded-md">
                                            <img src="https://cdn.countryflags.com/thumbs/india/flag-400.png" alt="India Flag" className=" pl-[10px] h-6" />
                                            <h1 className=' md:text-[20px] md:text-[23px] text-[14px] font-light text-[#FFFFFF]'>IND</h1>
                                            <h1 className=' md:text-[20px] md:text-[23px] text-[14px] font-light text-[#FFFFFF]'>+91</h1>



                                        </div> */}


                                    <div className='mt-4'>
                                        <input type="number" name='phone' placeholder="Phone Number" className="p-2 rounded-md w-full md:w-[630px] bg-[#222222] text-[16px] md:text-[20px] font-light  text-white " onChange={handleInputChange} required />
                                        {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                                    </div>

                                </div>

                                {/* Delivery Address */}
                                <div className="bg-transparent  md:w-[629px] md:h-[360px] w-full h-auto">
                                    <h2 className="md:text-[32px] text-[22px] font-light mb-4">Delivery Address</h2>
                                    <div>
                                        <input type="text" name='address' placeholder="Address" className="p-2 rounded-md w-full bg-[#222222]  text-[16px] md:text-[20px] font-light  text-white" onChange={handleInputChange} required />
                                        {errors.address && <p className="text-red-500">{errors.address}</p>}
                                    </div>
                                    <div>
                                        <input type="text" name='city' placeholder="City" className="p-2 rounded-md w-full bg-[#222222]  text-[16px] md:text-[20px] font-light  text-white mt-4" onChange={handleInputChange} required />
                                        {errors.city && <p className="text-red-500">{errors.city}</p>}
                                    </div>
                                    <div className="flex flex-col  md:flex-row gap-4">

                                        <div>
                                            <input type="text" name='state' placeholder="State" className="flex-1 p-2 rounded-md bg-[#222222] w-full md:w-[307px] text-[16px] md:text-[20px] font-light   text-white mt-4" onChange={handleInputChange} required />
                                            {errors.state && <p className="text-red-500">{errors.state}</p>}
                                        </div>
                                        {/* <div>
                                            <input type="text" name='country' placeholder="Country" className="flex-1 p-2 rounded-md bg-[#222222] w-full md:w-[307px] text-[16px] md:text-[20px] font-light   text-white" onChange={handleInputChange} required />
                                            {errors.country && <p className="text-red-500">{errors.country}</p>}

                                        </div> */}
                                         <div>
                                            <input type="number" name='zip' placeholder="Pin Code" className="flex-1 p-2 rounded-md bg-[#222222] w-full md:w-[307px]  text-[16px] md:text-[20px] font-light  text-white mt-0 md:mt-4" onChange={handleInputChange} required />

                                            {errors.zip && <p className="text-red-500">{errors.zip}</p>}
                                        </div>


                                    </div>
                                 
                                </div>

                                {/* Payment Option */}

                            </div>
                        </form>
                        {/* Right Section - Order Summary */}
                        <div className="mb-[20px] md:mb-[0px] bg-[#222222c] border border-[#ECBC56]  p-5 md:p-9 rounded-[20px] md:w-[451px] md:h-[441px] w-full h-auto flex flex-col">
                            <div>
                                <h3 className=" text-[23px] md:text-[30px] text-[#ECBC56] font-semibold mb-4">ORDER TOTAL</h3>
                                <div className="h-[171px]">
                                    <div className="flex justify-between">
                                        <span className='text-[#DBDBDB]  text-[18px] md:text-[25px] font-light'>Subtotal</span>
                                        <span className='text-[#DBDBDB] md:text-[25px] font-light'>₹ {subtotal}</span>
                                    </div>
                                    <div className="flex justify-between mb-[30px]">
                                        <span className='text-[#DBDBDB]  text-[18px] md:text-[25px] font-light'>Shipping</span>
                                        <span className='text-[#DBDBDB] text-[18px] md:text-[25px] font-light'>{shipping}</span>
                                    </div>
                                    <hr className='my-4 border-[#ECBC56] border-dashed mb-[30px]' />
                                    <div className="flex justify-between ">
                                        <span className='text-[#ECBC56] text-[18px] md:text-[25px] font-light'>Total</span>
                                        <span className='text-[#ECBC56] text-[18px] md:text-[25px] font-light'>₹ {totalAmount}</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='md:mt-[40px]'>
                                <button onClick={handleSubmit} className="md:py-[0px] py-[4px] md:w-[384px] md:h-[52px] w-full h-auto text-[16px] md:text-[25px] mb-[15px] bg-[#ECBC56] text-black font-normal rounded-[5px] text-center">Place order</button>


                            </div> */}





                            <div onClick={handleSubmit} className="h-[52px] flex items-center justify-center md:mt-[40px] p-4 bg-[#ECBC56] rounded-[5px] hover:cursor-pointer">
                                <div className="h-[44px]">

                                    <img
                                        src={razorpayLogo}
                                        alt="Razorpay Logo"
                                        className="w-full h-full"
                                    />
                                </div>
                                <h3

                                    className="font-semibold text-[20px] md:text-[32px] text-black rounded-[5px]  px-4 py-2 md:py-0"
                                >
                                    Place Order
                                </h3>



                            </div>




                        </div>
                    </div>
                </>)}


        </div>
    );
};

export default CheckoutPage;